import React from "react"

/*
 * Image will be allotted space according to httowdpercent ()
 */

const SizedImage = ({
	className, httowdpercent, ...props
 }) => {
	return <div className={typeof className === "undefined"?"":className}>
		<div style={{position:"relative", width:"100%",display:"block", overflow:"hidden", paddingBottom:typeof httowdpercent === "undefined"?"100%":""+httowdpercent}}>
			<img
				style={{position:"absolute", width:"100%",top:"0", left:"0"}}
				{...props}
			/>
		</div>
	</div>
}

export default SizedImage
