import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {Link} from "gatsby"
import Layout from "../components/layout"

import formatBOYSEN from "../../lib/formatBoysen"


import WebappImage from "../components/image/webappimage"

import * as styles from "../styles/pages/colorcollection.module.css"

const imgthecolorlibrary = "../images/colorcollection/colorlibrary.jpg"

const listproduct = require('../content/data/listproduct.json');

const ColorcollectionPage = () => (
	<Layout activePath='colorcollection' pageTitle="Color Collection" pageDescription="Elegant neutrals, soft pastels, bold hues, and everything in between. Browse more than a thousand paint colors from the BOYSEN fan decks and released through BOYSEN Color Trend. ">
		<h1 className={"text-centered textcolor-primary "}>Color Collection</h1>
		<div className={"text-centered textcolor-primary "+styles.introcontent}>
			<div><div>Elegant neutrals, soft pastels, <span className={styles.introcontentmobilebreak}>bold hues, and everything in between.</span></div> Browse more than a thousand paint colors from the <span className="nobr">BOYSEN<span className={"registeredicon"}>&reg;</span></span> fan decks and released through <span className="nobr">BOYSEN<span className={"registeredicon"}>&reg;</span></span> Color Trend.</div>
		</div>
		<div className={styles.listholder}>
			<Link to={"/colorlibrary"} title={"The Color Library"} className={"text-centered textcolor-primary "+styles.listitem}>
				<StaticImage layout={"fullWidth"}  src={imgthecolorlibrary} alt={"The Color Library"} />
				<div className={styles.viewcollection}>The Color Library</div>
			</Link>
			{
				listproduct.listproductobj.map((product, idx)=>{
					if (product.catid !== "2") {
						return <></>
					} else if (product.imageurl.length < 1) {
						return <></>
					} else if (product.name.toLowerCase().indexOf(" color trend ") > 0) {
						if (listproduct.listproductobj[idx-1].name.toLowerCase().indexOf(" color trend ") > 0) {
							// not the first (i.e. latest) color trend
							return <></>
						}
					}
					return <Link to={"/colorcollection/"+product.url} title={formatBOYSEN.stripNameTags(product.name)} className={"text-centered textcolor-primary "+styles.listitem}>
						<WebappImage httowdpercent={"37.5%"} srcurl={product.imageurl} origwd={1920} alt={formatBOYSEN.stripNameTags(product.name)} />
						<div className={styles.viewcollection}>View Collection</div>
					</Link>
				})
			}
		</div>

	</Layout>
)

export default ColorcollectionPage
