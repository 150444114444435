
const stripNameTags = (curstring) => {
	return curstring.replace(/(<([^>]+)>)/gi, "").replace(/&reg;/g,"").replace(/&trade;/g,"");
}


const trimstr = (str) => {
	if (str === null) {
		return "";
	}
	return (str+"").replace(/^\s+|\s+$/g,'');
}

module.exports = {
	stripNameTags,
	trimstr
}

