import React from "react"

import SizedImage from "./sizedimage"

/*
 * Image will be allotted space according to httowdpercent ()
 */

const WebappImage = ({
	className, srcurl, origwd, httowdpercent, altname
 }) => {
	const customclass = typeof className === "undefined"?"":className
	const srcsetval = srcurl+" "+origwd+"w, "+srcurl.replace("/full/","/high/")+" 2048w, "+srcurl.replace("/full/","/med/")+" 1200w, "+srcurl.replace("/full/","/low/")+" 500w"
	return (<>
		{typeof httowdpercent === "undefined"?
			<img
				className={customclass}
				alt={altname}
				src={srcurl}
				srcset={srcsetval}
				/>
		:
			<SizedImage
				className={customclass}
				httowdpercent={httowdpercent}
				alt={altname}
				src={srcurl}
				srcset={srcsetval}
			/>
		}
	</>)
}

export default WebappImage
